<template>
  <div>
    <div class="d-none d-lg-flex card">
      <div class="card-header d-flex justify-content-between">
        <h4 class="mb-0">IOTM News</h4>
        <a href="/newsletter/subscribe" class="btn btn-sm btn-primary">
          Join Our Newsletter
        </a>
      </div>

      <div class="card-body">
        <div class="mb-3 twitter-scroll-container">
          <a
            class="twitter-timeline"
            href="https://twitter.com/Indieonthemove?ref_src=twsrc%5Etfw"
            >Tweets by Indieonthemove</a
          >
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center" v-if="!hideAds">
      <div id="ads-medium-rectangle" class="mt-2 d-none d-lg-flex">
        <component
          is="script"
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          async
        ></component>
        <ins
          class="adsbygoogle"
          style="height: 250px; width: 300px; display: block"
          data-ad-client="ca-pub-7294664740924242"
          data-ad-slot="6104773750"
          data-ad-format="medium-rectangle"
        ></ins>
      </div>
    </div>

    <div class="mt-2 d-none d-lg-flex card">
      <div class="card-header d-flex justify-content-between">
        <h4 class="mb-0">Venue Reviews</h4>
        <a href="/venue-commentsreviews" class="btn btn-sm btn-primary">
          More Reviews
        </a>
      </div>
      <div class="card-body">
        <reviews limit="5"></reviews>
      </div>
    </div>

    <div class="d-flex justify-content-center" v-if="!hideAds">
      <div id="ads-skyscraper" class="mt-2 d-none d-lg-flex">
        <ins
          class="adsbygoogle"
          style="height: 600px; width: 300px; display: block"
          data-ad-client="ca-pub-7294664740924242"
          data-ad-slot="6104773750"
          data-ad-format="skyscraper"
        ></ins>
      </div>
    </div>
  </div>
</template>

<script>
import reviews from "../components/home/reviews.vue";

export default {
  components: { reviews },

  props: {
    statusCode: {
      default: 200,
    },
  },

  mounted() {
    if (!this.hideAds) {
      this.adsenseMediumRectangle = document.getElementById(
        "ads-medium-rectangle",
      ).innerHTML;
      this.adsenseSkyScraper =
        document.getElementById("ads-skyscraper").innerHTML;
    }
  },
  computed: {
    hideAds() {
      return (
        (window.User && window.User["old_browser"]) ||
        window.User.is_premium ||
        window.User.is_deluxe ||
        this.statusCode != 200
      );
    },
  },
};
</script>

<style>
.twitter-scroll-container {
  max-height: 40vh;
  overflow: scroll;
}
</style>
